import { Inject, Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SignalRConnection } from '../models/signalr.model';
import { Message, Profile, SubscribeResponse, UxItem, Attachment } from '../models/chatstyle.model';
import { environments } from '../environments/environments';

import { HubConnection } from '@microsoft/signalr';
import * as SignalR from '@microsoft/signalr';

@Injectable()
export class SignalRChannelService {

  constructor(private http: HttpClient) {
    //this.url = "https://devchatbox.chatstyle.ai/api";
    //"https://chatstylesignalr-dev-east.azurewebsites.net/api";
    // "https://chatbox.chatstyle.ai/api";
  }

  private hubConnection: HubConnection;
  public MessageReceived: EventEmitter<Message> = new EventEmitter<Message>();
  public ProfileUpdated: EventEmitter<Profile> = new EventEmitter<Profile>();
  public Subscribed: EventEmitter<SubscribeResponse> = new EventEmitter<SubscribeResponse>();
  public StateChanged: EventEmitter<string> = new EventEmitter<string>();

  private userId: string;
  private botId: string;
  private botVersion: string;
  private loadHistory: boolean;
  private autoStart: boolean;
  private debugMode: boolean;
  private createNew: boolean;
  public tag: string;
  private state: string;

  private headers: HttpHeaders;

  public conversationID: string;
  public connectionState: string;

  public url: string;

  updateIds(conversationId, userId) {

    this.userId = userId;
    this.conversationID = conversationId;

    this.headers = this.headers.set('x-ms-signalr-userid', this.userId);
    this.headers = this.headers.set('cv', this.conversationID);
  }

  init(id, bot, version, loadHistory, autoStart, debugMode, createNew, env, authDetails  ) {

    this.url = environments[env].url; // "https://devchatbox.chatstyle.ai/api";
    this.connectionState = '';

    this.botId = bot;
    this.botVersion = version;

    //this.conversationID = conversation;
    this.loadHistory = loadHistory;
    this.autoStart = autoStart;
    this.debugMode = debugMode;
    this.createNew = createNew;

    this.headers = new HttpHeaders();

    let paramsString = window.location.search;
    let searchParams = new URLSearchParams(paramsString);

    if (searchParams.has("tag")) {
      this.tag = searchParams.get("tag");
      console.log('tag=' + this.tag);
      this.headers = this.headers.set('tag', this.tag);
      id = '';
    }

    this.userId = id;

    this.headers = this.headers.set('x-ms-signalr-userid', this.userId);

    if (searchParams.has("cvx")) {
      this.conversationID = searchParams.get("cvx");
      console.log('cvx=' + this.conversationID);
      this.headers = this.headers.set('cv', this.conversationID);
    }

    if (searchParams.has("cvstate")) {
      this.state = searchParams.get("cvstate");
      console.log('state=' + this.state);
      this.headers = this.headers.set('cvstate', this.state);
    }
    //if (conversation) {
    //  this.headers.set('cv', this.conversationID);
    //}

    let postUrl = this.url + '/subscribe?botId=' + this.botId + '&loadhistory=' + this.loadHistory +
      '&autostart=' + this.autoStart + '&debugMode=' + this.debugMode + '&createNew=' + this.createNew +
      '&version=' + this.botVersion;
    if (authDetails) {
      if (authDetails.email) {
        postUrl += '&email=' + authDetails.email;
      }
      if (authDetails.displayName) {
        postUrl += '&displayName=' + authDetails.displayName;
      }
      if (authDetails.companyName) {
        postUrl += '&companyName=' + authDetails.companyName;
      }
    }

    this.http.post<SubscribeResponse>(postUrl,
      '', { headers: this.headers })
      .subscribe(
        result => {
          console.log('Subscribed to conversation');

          this.userId = result.audienceID;

          this.headers = new HttpHeaders().set('x-ms-signalr-userid', this.userId);
          this.conversationID = result.conversationID;

          this.Subscribed.emit(result);

          //const resp: SubscribeResponse = {
          //  ConversationID: result.ConversationID,
          //  BotID: result.BotID,
          //  ImageUrl: result.ImageUrl
          //}
          let joinConversationUrl = this.url + '/joinConversation?botId=' + this.botId + '&conversationId=' + result.conversationID;
          this.http.post(joinConversationUrl,
            '', { headers: this.headers })
            .subscribe(
              result => {
                console.log('Joined conversation');

                this.stopConnection();

                this.getSignalRConnection().subscribe(con => {

                  const options = {
                    accessTokenFactory: () => con.accessToken
                  };

                  this.hubConnection = new SignalR.HubConnectionBuilder()
                    .withUrl(con.url, options)
                    .withAutomaticReconnect()
                    .configureLogging(SignalR.LogLevel.Information)
                    .build();

                  this.hubConnection.keepAliveIntervalInMilliseconds = 1000 * 5;
                  this.hubConnection.serverTimeoutInMilliseconds = 1000 * 20;

                  this.hubConnection.start()
                    .then(() => {
                      this.connectionState = 'connected';
                      this.StateChanged.emit(this.connectionState);
                    })
                    .catch((a) => {
                      this.connectionState = 'disconnected';
                      this.StateChanged.emit(this.connectionState);
                      //console.log('not connected' + a);
                    });

                  this.hubConnection.onclose(async () => {
                    this.connectionState = 'disconnected';
                    this.StateChanged.emit(this.connectionState);
                  });

                  this.hubConnection.onreconnecting(async () => {
                    this.connectionState = 'reconnecting';
                    this.StateChanged.emit(this.connectionState);
                  });

                  this.hubConnection.onreconnected(async () => {
                    this.connectionState = 'connected';
                    this.StateChanged.emit(this.connectionState);
                  });

                  this.hubConnection.on('relay', data => {
                    console.log(data);
                    const msg: Message = {
                      attachments: [],
                      body: data.Body,
                      channel: data.Channel,
                      conversationID: data.ConversationID,
                      recipientID: data.RecipientID,
                      senderID: data.SenderID,
                      payload: data.Payload,
                      text: data.Text,
                      time: data.Time,
                      uxItems: [],
                      fromDisplayName: data.FromDisplayName,
                      toDisplayName: data.ToDisplayName,
                      tag: data.Tag,
                      info: data.Info,
                      blockInput: data.BlockInput,
                      inputType: data.InputType,
                      ttl: data.TimeToLive,
                      blockAttachments: data.BlockAttachments,
                      data: data.Data,
                      isCommand: data.IsCommand
                    };

                    data.UxItems.forEach(i => {
                      const ui: UxItem = {
                        type: i.Type,
                        action: i.Action,
                        label: i.Label,
                        description: i.Description,
                        value: i.Value,
                        imageUrl: i.ImageUrl,
                        url: i.Url,
                        data: i.Data
                      };

                      msg.uxItems.push(ui);
                    });

                    data.Attachments.forEach(a => {
                      const att: Attachment = {
                        name: a.Name,
                        data: a.Data,
                        url: a.Url,
                        attachmentId: a.AttachmentId,
                        contentType: a.ContentType
                      };

                      msg.attachments.push(att);
                    });
                    this.MessageReceived.emit(msg);
                  });

                  this.hubConnection.on('debugrelay', data => {

                  });

                  this.hubConnection.on('profileUpdate', data => {
                    this.ProfileUpdated.emit(data);
                  });


                });

                if (this.autoStart) {
                  let startConversationUrl = this.url + '/startConversation?botId=' + this.botId + '&conversationId=' + this.conversationID;
                  this.http.post(startConversationUrl,
                    '', { headers: this.headers })
                    .subscribe(
                      result => {
                        console.log('Starting conversation');
                      });

                }
              });



        });

  }

  stopConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop();
      this.hubConnection = null;
    }
  }

  reconnect() {
    if (this.hubConnection) {
      this.hubConnection.stop();
    }
    this.connectionState = 'reconnecting';
    this.StateChanged.emit(this.connectionState);
    this.hubConnection.start()
      .then(() => {
        this.connectionState = 'connected';
        this.StateChanged.emit(this.connectionState);
      })
      .catch((a) => {
        this.connectionState = 'disconnected';
        this.StateChanged.emit(this.connectionState);
        //console.log('not connected' + a);
      });
  }

  private getSignalRConnection(): Observable<SignalRConnection> {
    return this.http.post<SignalRConnection>(
      this.url + '/negotiate', {}, { headers: this.headers }
    );
  }

  public sendMessage(msg: Message): Promise<string> {
    const promise = this.http.post<string>(this.url + '/relay', msg, { headers: this.headers }).toPromise();
    return promise;
  }

  public sendMessageEx(data: FormData): Promise<string> {
    const promise = this.http.post<string>(this.url + '/relayEx', data, { headers: this.headers }).toPromise();
    return promise;

  }

  public downloadAttachment(attachment) {
    let fid = attachment.attachmentId.replaceAll("/", "-");
    this.http.get<Blob>(this.url + '/attachment/?id=' + fid, { observe: 'response', responseType: 'blob' as 'json' })
      .subscribe(
        (response: HttpResponse<Blob>) => {
          let filename: string = this.getFileName(response);
          if (filename === '') filename = attachment.name;
          let binaryData = [];
          binaryData.push(response.body);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      );
  }


  public getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get('content-disposition');
      //const r = /(?:filename=")(.+)(?:")/
      //filename = r.exec(contentDisposition)[1];
      filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
    }
    catch (e) {
      filename = '';
    }
    return filename
  }

}
