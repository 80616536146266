
export const environments = {
  "dev": {
    "url": "https://devchatbox.chatstyle.ai/api",
    "clientId": "85e56b17-4e64-4e44-9168-b9fb4debcbc8",
    "authority": "https://login.microsoftonline.com/common",
    "redirectUri": "http://localhost:7071/api/auth"
  },
  "local": {
    "url": "http://localhost:7071/api",
    "clientId": "85e56b17-4e64-4e44-9168-b9fb4debcbc8",
    "authority": "https://login.microsoftonline.com/common",
    "redirectUri": "http://localhost:7071/api/auth"
  },
  "production": {
    "url": "https://chatbox.chatstyle.ai/api",
    "clientId": "cbd89333-51ef-4644-9d0b-db57950b2bcc",
    "authority": "https://login.microsoftonline.com/common",
    "redirectUri": "http://localhost:7071/api/auth"
  }
};
